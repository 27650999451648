$neo__font__familyText: "neo__text", "Helvetica Neue", Arial, Helvetica, sans-serif;
$neo__font__familyTextBlack: "neo__textBlack", "Helvetica Neue", Arial, Helvetica, sans-serif;
$neo__font__familyTextLight: "neo__textLight", "Helvetica Neue", Arial, Helvetica, sans-serif;

@font-face {
  font-family: "neo__text";
  font-weight: 400;
  font-style: normal;
  src: url("~@style/fonts/roboto-v30-latin-regular.woff2") format("woff2");
}

@font-face {
  font-family: "neo__text";
  font-weight: 400;
  font-style: italic;
  src: url("~@style/fonts/roboto-v30-latin-italic.woff2") format("woff2");
}

@font-face {
  font-family: "neo__textLight";
  font-weight: 300;
  font-style: normal;
  src: url("~@style/fonts/roboto-v30-latin-300.woff2") format("woff2");
}

@font-face {
  font-family: "neo__textLight";
  font-weight: 300;
  font-style: italic;
  src: url("~@style/fonts/roboto-v30-latin-300italic.woff2") format("woff2");
}

@font-face {
  font-family: "neo__text";
  font-weight: 500;
  font-style: normal;
  src: url("~@style/fonts/roboto-v30-latin-500.woff2") format("woff2");
}

@font-face {
  font-family: "neo__text";
  font-weight: 500;
  font-style: italic;
  src: url("~@style/fonts/roboto-v30-latin-500italic.woff2") format("woff2");
}

@font-face {
  font-family: "neo__textBlack";
  font-weight: 700;
  font-style: normal;
  src: url("~@style/fonts/roboto-v30-latin-700.woff2") format("woff2");
}

@font-face {
  font-family: "neo__textBlack";
  font-weight: 700;
  font-style: italic;
  src: url("~@style/fonts/roboto-v30-latin-700italic.woff2") format("woff2");
}

@mixin font__setNormal() {
  font-family: $neo__font__familyText;
  font-weight: 400;
}
@mixin font__setThin() {
  font-family: $neo__font__familyTextLight;
  font-weight: 300;
}
@mixin font__setStrong() {
  font-family: $neo__font__familyText;
  font-weight: 500;
}
@mixin font__setBlack() {
  font-family: $neo__font__familyTextBlack;
  font-weight: 700;
}
