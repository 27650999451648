.admin__buttonList {
  display: flex;
  justify-content: stretch;
  flex-direction: column;
  > * {
    flex-grow: 1;
    margin-bottom: 4px;
    &:last-child { margin-bottom: 0; }
  }
}
