.admin__sectionTitle {
  margin: 2em 0 .5em 0;

  h2 {
    margin: 0;
    @include font__setNormal();
    color: $neo__titleDarkBlue;
    font-size: 20px;
  }
}
