@keyframes __ani__adminFlashNotice__appear {
  0%    { opacity: 0; }
  40%   { opacity: 1; }
  70%   { opacity: .5; }
  100%  { opacity: 1; }
}

.admin__flashNotice__inner {
  padding: 12px 22px;
  background: $neo__green--dark;
  color: white;
  animation: __ani__adminFlashNotice__appear .6s both;
  a {
    transition: opacity .2s;
    &, &:hover, &:active {
      color: white;
      text-decoration: underline;
    }
    &:hover {
      opacity: .5;
    }
  }
  display: inline-block;
}

.admin__flashNotice--error .admin__flashNotice__inner {
  background: $neo__error;
}
