h1, h2, h3, h4 {
  @include font__setStrong();
}

a {
  cursor: pointer;
  &, &:hover, &:active {
    color: $neo__link;
    text-decoration: none;
  }
  &:hover {
    text-decoration: underline;
  }
}

span.tippy {
  text-decoration-line: underline;
  text-decoration-style: dashed;

  &.tip {
    text-decoration: none;
  }
}
