.admin__box {
  position: relative;
  padding: 12px;
  background: $neo__neutralGray--light;
}

.admin__box--medium {
  background: $neo__neutralGray--medium;
}

.admin__box__inner {
  position: relative;
}
