$adminSandbox__accentCol: aquamarine;
$adminSandbox__txt: #333;
$adminSandbox__txtLight: #bbb;
$adminSandbox__noteCol: mediumvioletred;

.adminSandbox__pageTitle {
  @include font__setThin();
  position: relative;
  text-align: center;
  color: $adminSandbox__txt;
  padding: 4px 0;
  margin-bottom: 2em;
  &::after {
    content: "";
    width: 40%;
    position: absolute;
    height: 1px;
    background: $adminSandbox__txtLight;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

.adminSandbox__sectionTitle {
  @include font__setThin();
  position: relative;
  text-align: center;
  color: $adminSandbox__txt;
  padding: 4px 0;
  margin-bottom: 2em;
  &::before {
    content: "";
    width: 8px;
    position: absolute;
    top: 4px;
    bottom: 4px;
    background: $adminSandbox__accentCol;
    left: 0;
  }
  &::after {
    content: "";
    width: 80%;
    position: absolute;
    height: 1px;
    background: $adminSandbox__accentCol;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0);
  }
}

.adminSandbox__note {
  @include font__setThin();
  strong { @include font__setStrong(); }
  margin: 1em;
  margin-bottom: 2em;
  border-left: 2px solid $adminSandbox__noteCol;
  padding: .5em;
}
