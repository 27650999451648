.admin__formElements {
  input {
    height: 44px;
    padding: 16px 8px;
    font-size: 14px;
    @include font__setNormal();
    color: $neo__txt;
    border: 1px solid $neo__neutralGray--medium;
    border-radius: 0;
    outline: 0;
    &:focus {
      outline: 2px solid lighten($neo__buttonBlue, 25%);
      border-radius: 2px;
    }
    &:disabled {
      background-color: rgb(226, 227, 229);
      border-color: $neo__neutralGray--light;
      color: $neo__txt--light;
    }
  }
  input[type=radio],
  input[type=checkbox] {
    height: unset;
    &:focus {
      outline: unset;
      border-radius: unset;
    }
  }
  input[type=datetime-local] {
    padding: 0 8px;
  }
  input[type=file] {
    border: unset;
    &.admin__form__file--withPreview {
      display: none;
    }
  }
  input.choices__input {
    border: unset;
    padding: 0;
    &:focus {
      outline: unset;
      border-radius: unset;
    }
  }
  .admin__form__fileLink {
    margin-top: 0.8em;
  }
  .admin__form__field--withError {
    input,
    select,
    select {
      border: 1px solid $neo__error;
    }
  }
  .admin__form__fieldErrors {
    color: $neo__error;
    font-size: .8em;
    @include font__setThin();
  }
  .admin__form__info {
    color: $neo__txt--light;
  }
}

a.admin__form__dangerLink {
  &, &:hover, &:active {
    color: $neo__error;
  }
}

.admin__form__actions--center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin__form__actions--withSeparators {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  > * {
    margin: 0 .25em;
    &:first-child { margin-inline-start: 0; }
    &:last-child { margin-inline-end: 0; }

    &::after {
      @include font__setThin();
      content: "|";
      color: lighten($neo__txt--light, 5%);
    }
    &:last-child {
      &::after {
        display: none;
      }
    }
  }
}
