.admin__mainNavigation {
  border-bottom: 1px solid #EFF0F2;
  padding: 0 80px;

  ul {
    margin: 0 auto;
    max-width: 880px;
    position: relative;
    min-height: 40px;
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;

    > li {
      padding: 0;
      padding-inline-end: 12px;
      display: flex;
      align-items: center;

      > a {
        @include font__setThin();
        color: $neo__txt--light;
        text-decoration: none;

        &:hover, &.selected {
          color: $neo__txt;
        }
      }
    }
  }
}
