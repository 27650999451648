$button__minHeight: 40px;

.admin__button {
  text-align: center;
  white-space: wrap;
  max-width: 100%;
  min-height: $button__minHeight;
  display: inline-flex;
  padding: 0 1.5em;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  @include font__setNormal();
  @include fontawesome__useIconAsBeforePseudoElement();
  text-transform: uppercase;
  &::before { // icon
    margin-inline-end: .5em;
  }
  --adminButton__bg: linear-gradient(to bottom, #4ea6a9, #439096);
  --adminButton__txt: white;
  --adminButton__border: #{$neo__buttonBlue};
  --adminButton__bg--hover: linear-gradient(to bottom, #56b9bd, #4aa1aa);
  --adminButton__txt--hover: white;
  --adminButton__border--hover: #{$neo__buttonBlue};

  border-radius: 15px;
  border: 3px solid var(--adminButton__border);
  background: var(--adminButton__bg);
  &, &:hover, &:active, &:visited {
    color: var(--adminButton__txt);
    text-decoration: none;
    .admin__button__title {
      color: var(--adminButton__txt);
      text-decoration: none;
    }
    &::before { color: var(--adminButton__txt); }
  }
  &:hover {
    color: var(--adminButton__txt--hover);
    background: var(--adminButton__bg--hover);
    border-color: var(--adminButton__border--hover);
    &::before { color: var(--adminButton__txt--hover); }
    .admin__button__title {
      color: var(--adminButton__txt--hover);
      text-decoration: none;
    }
  }
  cursor: pointer;
}
.admin__button--justIcon {
  padding: 8px 0;
  width: $button__minHeight;
  &::before { margin: 0; }
}
.admin__button--secondary {
  --adminButton__bg: transparent;
  --adminButton__txt: #{$neo__buttonBlue};
  --adminButton__border: #{$neo__buttonBlue};
  --adminButton__bg--hover: #{$neo__buttonBlue};
  --adminButton__txt--hover: white;
  --adminButton__border--hover: #{$neo__buttonBlue};
}
.admin__button--danger {
  --adminButton__bg: #{$neo__error};
  --adminButton__txt: white;
  --adminButton__border: #{$neo__error};
  --adminButton__bg--hover: transparent;
  --adminButton__txt--hover: #{$neo__error};
  --adminButton__border--hover: #{$neo__error};
}

span.admin__button,
.admin__button[disabled] {
  opacity: .4;
  cursor: not-allowed;
  &:hover {
    color: var(--adminButton__txt);
    background-color: var(--adminButton__bg);
    border-color: var(--adminButton__border);
    text-decoration: none;
    .admin__button__title {
      color: var(--adminButton__txt);
      text-decoration: none;
    }
    &::before { color: var(--adminButton__txt); }
  }
}
