.admin__twoCols {
  display: flex;
  justify-content: space-between;
  align-items: start;
  --adminTwoCols__ratio: .5;
  position: relative;

  > * {
    flex-grow: 0;
    flex-shrink: 0;
    position: relative;
    &:first-child {
      width: calc((100% * var(--adminTwoCols__ratio)) - 4px);
    }
    &:last-child {
      width: calc((100% * (1 - var(--adminTwoCols__ratio))) - 4px);
    }
    > * {
      &:first-child {
        margin-top: 0;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.admin__twoCols--sameHeight {
  align-items: stretch;
}

.admin__twoCols--hCenter {
  align-items: center;
}

.admin__twoCols--30 { --adminTwoCols__ratio: .3; }
.admin__twoCols--40 { --adminTwoCols__ratio: .4; }
.admin__twoCols--50 { --adminTwoCols__ratio: .5; }
.admin__twoCols--60 { --adminTwoCols__ratio: .6; }
.admin__twoCols--70 { --adminTwoCols__ratio: .7; }
.admin__twoCols--90 { --adminTwoCols__ratio: .9; }
