// Needs to reflect the structure of the pagination elements
// which are automatically generated by the "pagy" gem

nav.pagy-nav {
  display: flex;
  justify-content: center;
  align-items: center;

  > span {
    margin: 0 3px;
    padding: 3px;

    &.disabled {
      color: $neo__txt--light;
      cursor: not-allowed;
    }
  }
}
