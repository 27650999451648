.adminDashboard__section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;

  > * {
    flex-grow: 0;
    flex-shrink: 0;
    &:first-child {
      width: 30%;
    }
    &:last-child {
      flex: 1;
    }
  }
}
.adminDashboard__section__label {
  font-weight: bold;
}
.adminDashboard__serverTime {
  display: flex;
  flex-direction: row;
}

.adminDashboard__serverTime__time {
  margin-right: 10px;
}
