.admin__formLayouter {
  position: relative;
  form {
    width: 100%;
  }

  .choices__list--multiple .choices__item {
    background-color: $neo__buttonBlue;
    border-color: $neo__buttonBlue;
  }

  .choices__list--dropdown .choices__item--selectable, .choices__list[aria-expanded] .choices__item--selectable {
    padding-right: 10px;
    &::after { display: none; }
  }
}

.admin__formLayouter--label_next_to_input {
  *:not(.admin__filterForm) {
    .admin__form__field {
      display: flex;
      justify-content: stretch;
      flex-wrap: wrap;
      > label:first-child { margin-top: .8em; }
      > * { position: relative; }
      > *:first-child { width: 40%; flex-grow: 0; flex-shrink: 0; }
      > *:nth-child(2), > .choices { width: 60%; flex-grow: 0; flex-shrink: 0; }
      > .admin__form__fieldErrors { width: 60%; flex-grow: 0; flex-shrink: 0; margin-inline-start: 40%;}
    }
  }
  .admin__form__row--alignWithInputs {
    position: relative;
    width: 60%; flex-grow: 0; flex-shrink: 0; margin-inline-start: 40%;
  }
}

.admin__form__row {
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
}
.admin__form__row--center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin__formLayouter__radioGroup__item {
  margin: 12px 0;
  .admin__form__field {
    position: relative;
    .choices { width: 100% !important; }
  }
}

h3.admin__formLayouter__sectionTitle {
  @include font__setThin();
  margin: 0;
  font-size: 18px;
}
