.admin__tableWrapper {
  position: relative;
  table {
    width: 100%;
    position: relative;
    border-collapse: collapse;
  }
  th {
    text-align: start;
    @include font__setThin();
  }
  td, th {
    padding: 8px 4px;
  }
  td {
    border-bottom: 1px solid $neo__thinBorderLine;
  }
  tr:last-child td { border-bottom: 0; }
  thead {
    td, th {
      border-bottom: 1px solid $neo__thickBorderLine;
    }
  }
}

// cell format helpers
td, th {
  &.ta--start { text-align: start; }
  &.ta--end { text-align: end; }
  &.ta--center { text-align: center; }
  &.w--slim { width: 1%; }
  &.w--80 { width: 80%; }
  &.w--70 { width: 70%; }
  &.w--60 { width: 60%; }
  &.w--50 { width: 50%; }
  &.w--40 { width: 40%; }
  &.w--30 { width: 30%; }
  &.w--20 { width: 20%; }
  &.w--10 { width: 10%; }
  &.va--middle { vertical-align: middle; }
  &.c--header { background-color: $neo__lightBg; }
  &.f--bold { font-weight: bold; }
}
