.admin__filterForm {
  .choices__list--dropdown .choices__item--selectable, .choices__list[aria-expanded] .choices__item--selectable {
    padding-right: 10px;
    &::after { display: none; }
  }
}

.admin__filterForm__title {
  h3 {
    margin: 0;
    @include font__setThin();
    font-size: 1em;
  }
}

.admin__filterForm__rowWithInputs {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  gap: 0 .5em;
  > * {
    position: relative;
    margin: 4px 0;
    min-width: 120px;
    flex-grow: 1;
    flex-shrink: 1;
  }
  input[type=text] { max-width: 600px; }
}

.admin__filterForm__dateFromToWrapper {
  display: flex;
}
.admin__filterForm__inlineDateWithLabel {
  display: flex;
  justify-content: stretch;
  align-items: center;
  white-space: nowrap;
  > label {
    flex-grow: 0;
    text-align: end;
    min-width: 24px;
    font-size: .8em;
    margin-inline-end: 4px;
  }
  > input {
    flex-grow: 1;
    max-width: 240px;
  }
}

.admin__filterForm__actions {
  flex-grow: 0;
  flex-shrink: 0;
  min-width: unset;
}
