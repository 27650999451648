$fa-font-path: "~@fortawesome/fontawesome-free/webfonts";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/regular";

@import "~choices.js/src/styles/choices";

@import "@style/_defaults";
@import "@style/_fonts";
@import "@style/_typography";
@import "@style/_fontawesomeHelper";

html, body {
  padding: 0;
  margin: 0;
  outline: 0;
  position: relative;
  min-height: 100%;
  @include font__setNormal();
}

@import "@style/layout/_html";
@import "@style/layout/_pageSurround";
@import "@style/layout/_mainNavigation";
@import "@style/layout/_contentRow";
@import "@style/layout/_twoCols";
@import "@style/layout/_tableWrapper";

@import "@style/components/_box";
@import "@style/components/_breadcrumbNavigation";
@import "@style/components/_button";
@import "@style/components/_buttonList";
@import "@style/components/_filterForm";
@import "@style/components/_flashNotice";
@import "@style/components/_hr";
@import "@style/components/_pageTitle";
@import "@style/components/_pagination";
@import "@style/components/_sectionTitle";

@import "@style/admin_form/_formLayouter";
@import "@style/admin_form/_formElements";

@import "@style/page/_dashboard";
@import "@style/page/_sandbox";
