.admin__breadcrumbNavigation {
  @include font__setThin();
  font-size: .9em;

  > div {
    > a {
      &, &:hover, &:active {
        color: $neo__txt;
      }
    }


    > *:nth-last-child(1) { // hide leaf
      display: none;
    }
  }
}

.admin__breadcrumbNavigation__separator {
  margin: 0 .5em;
  &::before {
    content: "›";
    font-size: 1.2em;
    color: $neo__txt--light;
  }
}
