.admin__pageSurround {
  position: relative;
}

.admin__pageSurround__content {
  padding: 0 80px;
}

.admin__pageSurround__content__inner {
  margin: 0 auto;
  max-width: 880px;
  position: relative;
  min-height: 480px;
}

.admin__header {
  padding: 0 80px;
}
.admin__header__accountHolder {
  font-size: 0.8rem;
  text-align: right;
  color: $neo__txt--light;
  @include font__setThin();
  margin-bottom: 10px;
}

.admin__footer {
  margin-top: 4em;
  padding-bottom: 2em;
  text-align: center;
  color: $neo__txt--light;
  @include font__setThin();
  font-size: .8em;
  a {
    transition: opacity .2s;
    &, &:hover, &:visited {
      color: $neo__txt--light;
      text-decoration: none;
    }
    &:hover {
      opacity: .4;
    }
  }
}
